.triangleDown:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-top: 10px solid #555555;
  border-bottom: 0;
  position: absolute;
  right: 20px;
}

.triangleDown:hover:after {
  border-top: 10px solid white;
}

.triangleUp:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 10px solid #555555;
  border-top: 0;
  position: absolute;
  right: 20px;
}

.triangleUp:hover:after {
  border-bottom: 10px solid white;
}
