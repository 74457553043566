.reactMarkDown {
  width: 100%;
}

.reactMarkDown p {
  margin-bottom: 20px;
}

.reactMarkDown ul {
  margin-top: 1em;
  margin-bottom: 1em;
  list-style: disc outside none;
}

.reactMarkDown ul li {
  margin-left: 2em;
  display: list-item;
  text-align: -webkit-match-parent;
}
